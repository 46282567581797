<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">

            </p>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require('@/assets/images/avatars/13-small.png')"
              class="badge-minimal"
              badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center"
                         @click="$router.push('/setting')"
        >
          <feather-icon
              size="16"
              icon="SettingsIcon"
              class="mr-50"
          />
          <span>Настройки</span>
        </b-dropdown-item>

        <!--
                <b-dropdown-item link-class="d-flex align-items-center">
                  <feather-icon
                    size="16"
                    icon="MailIcon"
                    class="mr-50"
                  />
                  <span>Inbox</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                  <feather-icon
                    size="16"
                    icon="CheckSquareIcon"
                    class="mr-50"
                  />
                  <span>Task</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                  <feather-icon
                    size="16"
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span>Chat</span>
                </b-dropdown-item>
        -->

        <b-dropdown-divider/>

        <b-dropdown-item link-class="d-flex align-items-center"
                         @click="logOutUser"
        >
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Выйти</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {mapActions} from "vuex";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  methods: {
    ...mapActions('user', ['logOut']),
    async logOutUser() {
      await this.logOut();
      this.$router.push('/login');
    }
  }
}
</script>
