export default [
    {
        route: 'unit-report',
        title: 'Юнитка',
        icon: 'TrendingUpIcon',
    },
    {
        route: 'orders',
        title: 'Заказы',
        icon: 'ListIcon',
    },
    {
        title: 'Закупки',
        route: 'procurement',
        icon: 'ShoppingCartIcon',
    },
    {
        title: 'Товары',
        route: 'products',
        icon: 'ShoppingBagIcon',
    },
    {
        title: 'Поставки FBO',
        route: 'delivery',
        icon: 'PackageIcon',
    },
    {
        title: 'Wiki',
        route: 'wiki',
        icon: 'BookOpenIcon',
        /*        children: [
                    // 2nd level
                    {
                        title: 'Invoice',
                        icon: 'i-mdi-file-document-outline',
                        children: [
                            // 3rd level
                            {
                                title: 'List',
                                to: 'apps-invoice-list',
                            },
                        ],
                    },
                ],*/
    },
]